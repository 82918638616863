const baseURL = 'http://localhost:8080';

const config = {
  tokenUrl: `${baseURL}/v1/oauth2/token`,
  textToSpeechUrl: `${baseURL}/v1/task/text_to_speech`,
  muteSpeak: `${baseURL}/v1/audio/volume/stream_music`,
  servicesUrl: 'https://node1prod.dtix.tw/hotel',
  streamUrl: 'https://hotel.datavansolutions.com:9092/stream_rags',
  youngerBase: 'https://media2.frrut.net', // Added youngerBase key
  imageAi: 'https://node1prod.dtix.tw/younger', // Added youngerBase key
};

export default config;
