import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import config from './config';

const WiFiManagement = () => {
  const [csvData, setCsvData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState([]);
  const [wifiData, setWiFiData] = useState({});
  const [hasValidationError, setHasValidationError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the password cookie exists and matches the expected password
    const savedPassword = Cookies.get('adminPassword');
    if (savedPassword !== 'datavan123') {
      navigate('/admin'); // Redirect to the admin login page if not authenticated
    }
  }, [navigate]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setErrors([]); // Clear errors when a new file is selected
    setCsvData([]); // Clear existing CSV data when a new file is selected
  };

  const validateCsv = (data) => {
    const requiredHeaders = ['name', 'password', 'rooms'];
    const headerErrors = [];
    const contentErrors = [];
    const wifiData = {};

    // Check if headers match the required headers
    const headers = Object.keys(data[0]).map(header => header.trim());
    if (!requiredHeaders.every(header => headers.includes(header))) {
      headerErrors.push('CSV文件必須包含以下標題: name, password, rooms');
    }

    // Check if each row has all columns filled and track data lengths per wifi name
    data.forEach((row, index) => {
      const isEmptyRow = Object.values(row).every(value => !value || value.trim() === '');
      if (isEmptyRow) {
        contentErrors.push(`第 ${index + 1} 行是空的`);
      } else {
        requiredHeaders.forEach((header) => {
          if (!row[header] || row[header].trim() === '') {
            contentErrors.push(`第 ${index + 1} 行的列 ${header} 有空值`);
          }
        });

        // Track data per wifi name
        const wifiName = row['name'];
        const password = row['password'];
        const room = row['rooms'];

        if (!wifiData[wifiName]) {
          wifiData[wifiName] = { password, rooms: new Set() };
        } else if (wifiData[wifiName].password !== password) {
          contentErrors.push(`WiFi 名稱 ${wifiName} 在第 ${index + 1} 行的密碼與之前的記錄不一致`);
        }

        // Check if room number is unique
        if (wifiData[wifiName].rooms.has(room)) {
          contentErrors.push(`房號 ${room} 在WiFi名稱 ${wifiName} 中已存在`);
        } else {
          wifiData[wifiName].rooms.add(room);
        }
      }
    });

    // Check if there is at least one row of data
    if (data.length < 1) {
      contentErrors.push('CSV文件必須包含至少一行數據');
    }

    return [...headerErrors, ...contentErrors];
  };

  useEffect(() => {
    const fetchWiFiData = async () => {
      try {
        const response = await axios.post(config.servicesUrl, {
          hotel_id: "2222",
          command: "get_qs"
        });
        if (response.status === 200) {
          setWiFiData(response.data.wifi);
          console.log(JSON.stringify(response.data.wifi))
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error('無法獲取設置:', error);
        alert('無法獲取設置');
      }
    };

    fetchWiFiData();
  }, []);

  // Ensure wifiData is an object
  const wifiArray = Object.keys(wifiData).flatMap(name =>
    wifiData[name].map(({ password, rooms }) => ({
      name,
      password,
      room: rooms
    }))
  );

  const handleFileUpload = () => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const cleanedData = results.data.map(row => {
            const cleanedRow = {};
            Object.keys(row).forEach(key => {
              cleanedRow[key.trim()] = row[key] ? row[key].trim() : '';
            });
            return cleanedRow;
          }).filter(row => !Object.values(row).every(value => value === ''));

          const validationErrors = validateCsv(cleanedData);
          if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setCsvData([]); // Clear existing CSV data when there are validation errors
            setHasValidationError(true); // Set validation error state
          } else {
            setCsvData(cleanedData);
            setErrors([]);
            setHasValidationError(false); // Clear validation error state
          }
        },
        error: (error) => {
          console.error('解析CSV文件時出錯:', error);
          setErrors(['解析CSV文件時出錯']);
          setCsvData([]); // Clear existing CSV data when there is an error parsing the file
          setHasValidationError(true); // Set validation error state
        }
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const formattedData = csvData.reduce((acc, row) => {
        const { name, password, rooms } = row;
        if (!acc[name]) acc[name] = [];
        acc[name].push({ password, rooms });
        return acc;
      }, {});

      const dataToSubmit = {
        hotel_id: "2222",
        command: "update_wifi",
        wifi: formattedData
      };

      const response = await axios.post(config.servicesUrl, dataToSubmit);
      if (response.status === 200) {
        console.log('WiFi更新成功!', response.data);
        alert('更新成功');
        window.location.reload(); // Reload the page
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('無法更新WiFi:', error);
      console.log('無法更新WiFi');
    }
  };

  const downloadTemplate = () => {
    const csvContent = [
      ['name', 'password', 'rooms'],
      ['hi_pow2F', '2ddd32332233', '203'],
      ['hi_pow2F', '2ddd32332233', '495'],
      ['hi_pow2F', '2ddd32332233', '235'],
      ['hi_P4F', '1111332233', '333'],
      ['hi_P4F', '1111332233', '445'],
      ['hi_P4F', '1111332233', '999']
    ];
    const csvString = Papa.unparse(csvContent);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'wifi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <h1>WiFi管理</h1>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <Button onClick={handleFileUpload}>上傳</Button><br />
      <div className="d-flex justify-content-end"><Button onClick={downloadTemplate}>下載模板</Button></div>

      <p>請點擊下載模板並確保所有字段都已填寫完整。填寫時務必每格不能含有 "," 逗號,否則會有錯誤</p>

      {errors.length > 0 && (
        <div>
          <h2>錯誤</h2>
          <ul>
            {errors.map((error, index) => (
              <li key={index} style={{ color: 'red' }}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      {csvData.length > 0 && !hasValidationError && (
        <div>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              onClick={handleSubmit}
              style={{ fontSize: '1.5rem', padding: '1rem 2rem' }} // Increase font size and padding
            >
              提交
            </Button>
          </div>
          <h2>預覽</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Table striped bordered hover style={{ width: '1000px' }}>
              <thead>
                <tr style={{ backgroundColor: '#343a40', color: '#ffffff' }}>{/* Dark grey background and white text */}
                  {Object.keys(csvData[0]).map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, idx) => (
                      <td key={idx}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      {csvData.length === 0 && wifiArray.length > 0 && !hasValidationError && (
        <div>
          <h2>目前WiFi設置</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Table striped bordered hover style={{ width: '1000px' }}>
              <thead>
                <tr style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                  <th>name</th>
                  <th>password</th>
                  <th>room</th>
                </tr>
              </thead>
              <tbody>
                {wifiArray.map((row, index) => (
                  <tr key={index}>
                    <td>{row.name}</td>
                    <td>{row.password}</td>
                    <td>{row.room}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export default WiFiManagement;
