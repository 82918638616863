import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import './App1.css'; // Import your CSS file here

function App() {
    const [inputText, setInputText] = useState('');
    const [responseText, setResponseText] = useState('');
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [talking, setTalking] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [language] = useState('zh-TW'); // Default language is Traditional Chinese
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [thinking, setThinking] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [queue, setQueue] = useState([]);
    const [sessionStarted, setSessionStarted] = useState(false);
    const [initialQueueSize, setInitialQueueSize] = useState(0);

    const recognitionRef = useRef(null);
    const inputTextRef = useRef(inputText);
    const talkVideoRef = useRef(null);
    const stopVideoRef = useRef(null);

    useEffect(() => {
        let timer = null;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0) {
            setInputText('');
            setResponseText('');
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const processText = useCallback((text) => {
        const parsedData = JSON.parse(`[${text.replace(/}\s*{/g, '},{')}]`);
        let extractedContent = parsedData.map(item => item.choices[0].delta.content).join("");

        setResponseText(extractedContent);
        const sentences = extractedContent.split(/[.!?，\uFF1F\uFF5E\u3002\n\uFF01]+/).filter(Boolean);

        setInitialQueueSize(sentences.length);
        setQueue(sentences);
        if (sentences.length > 0) setTalking(true);
    }, []);

    const handleSubmit = useCallback(async (text) => {
        setIsButtonDisabled(true);
        setThinking(true);
        setCountdown(null);

        const requestData = {
            talk: text,
            name: "room235"
        };

        try {
            const response = await fetch('https://hotel.datavansolutions.com:9092/stream_rags', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const reader = response.body.getReader();
            let buffer = '';

            const processStream = async ({ done, value }) => {
                if (done) {
                    processText(buffer);
                    return;
                }
                const textChunk = new TextDecoder("utf-8").decode(value);
                buffer += textChunk;
                return reader.read().then(processStream);
            };

            reader.read().then(processStream);
        } catch (error) {
            console.error('Failed to fetch:', error);
            setResponseText('Failed to fetch data at hotel api post');
            setTimeout(() => window.location.reload(), 20000);
        }
    }, [processText]);

    useEffect(() => {
        inputTextRef.current = inputText;
    }, [inputText]);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recognition = new SpeechRecognition();
            recognition.lang = language;
            recognition.interimResults = true;
            recognition.maxAlternatives = 1;

            recognition.onresult = event => {
                const transcript = event.results[0][0].transcript;
                setInputText(transcript);
                console.log('transcript:', transcript);
            };

            recognition.onstart = () => setIsListening(true);

            recognition.onend = () => {
                setIsListening(false);
                console.log('inputText at end:', inputTextRef.current);
                handleSubmit(inputTextRef.current);
            };

            recognition.onerror = event => {
                console.error('Recognition error:', event.error);
                setTimeout(() => {
                    window.location.reload();
                }, 20000);
            };

            recognitionRef.current = recognition;
        } else {
            console.warn("This browser doesn't support speech recognition.");
        }
    }, [handleSubmit, language]);

    const startListening = () => {
        setInputText('');
        setResponseText('');
        recognitionRef.current?.start();
        setIsButtonDisabled(true);
    };

    const speak = useCallback((textToSpeak) => {
        if (!textToSpeak.trim()) return;

        window.speechSynthesis.cancel();
        const utterance = new SpeechSynthesisUtterance(textToSpeak);
        utterance.lang = language;
        utterance.rate = 1.2; // Set the speech speed to 1.2x

        utterance.onstart = () => {
            setIsSpeaking(true);
            if (queue.length === initialQueueSize - 1) {
                setSessionStarted(true);
                setTalking(true);
                setThinking(false);
            }
        };

        utterance.onend = () => {
            setIsSpeaking(false);
            if (queue.length === 0 && !isSpeaking) {
                setTalking(false);
                setIsButtonDisabled(false);
                setCountdown(10);
            }
        };

        utterance.onerror = event => {
            console.error('Speech synthesis error:', event);
            setTimeout(() => window.location.reload(), 20000);
        };

        window.speechSynthesis.speak(utterance);
    }, [queue, isSpeaking, language, initialQueueSize]);

    useEffect(() => {
        if (!isSpeaking && queue.length > 0) {
            const nextSentence = queue.shift();
            speak(nextSentence);
        }
    }, [queue, isSpeaking, speak, talking, sessionStarted]);

    useEffect(() => {
        const currentTalkVideo = talkVideoRef.current;
        const currentStopVideo = stopVideoRef.current;

        if (currentTalkVideo && currentStopVideo) {
            currentTalkVideo.play();
            currentStopVideo.play();
        }

        const handleTimeUpdate = () => {
            const buffer = 0.5;
            if (currentTalkVideo.currentTime > currentTalkVideo.duration - buffer) {
                currentTalkVideo.currentTime = 0;
                currentTalkVideo.play();
            }
            if (currentStopVideo.currentTime > currentStopVideo.duration - buffer) {
                currentStopVideo.currentTime = 0;
                currentStopVideo.play();
            }
        };

        if (talking) {
            currentTalkVideo.play();
        } else {
            currentStopVideo.play();
        }

        currentTalkVideo.addEventListener('timeupdate', handleTimeUpdate);
        currentStopVideo.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            if (currentTalkVideo) {
                currentTalkVideo.removeEventListener('timeupdate', handleTimeUpdate);
            }
            if (currentStopVideo) {
                currentStopVideo.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, [talking]);

    return (
        <div className="App" style={{ width: '1080px', height: '1900px', position: 'relative' }}>
            <div className="video-container" style={{ position: 'absolute', top: '0', left: '0', width: '1080px', height: '300px' }}>
                <video
                    ref={talkVideoRef}
                    src="https://media.dtix.tw/hotel_2222/1715242248437.webm"
                    className={`w-full h-full absolute top-0 ${talking ? 'opacity-100' : 'opacity-0'}`}
                    style={{ transformOrigin: 'center' }}
                    loop
                    muted
                    playsInline
                    preload="auto"
                />
                <video
                    ref={stopVideoRef}
                    src="https://media.dtix.tw/hotel_2222/1715242356675.webm"
                    className={`w-full h-full absolute top-0 ${talking ? 'opacity-0' : 'opacity-100'}`}
                    style={{ transformOrigin: 'center' }}
                    loop
                    muted
                    playsInline
                    preload="auto"
                />
            </div>

            <div className="response-container" style={{ position: 'absolute', top: '160px', left: '40px', width: '500px', height: '400px', justifyContent: 'center', alignItems: 'center' }}>
                <div
                    dangerouslySetInnerHTML={{ __html: responseText }}
                    style={{
                        width: "100%",
                        height: "100%",
                        fontSize: '35px',
                        border: '0.5px solid #90EE90',
                        borderRadius: '5px',
                        whiteSpace: 'pre-wrap',
                        backgroundColor: 'white',
                        padding: '10px',
                        boxSizing: 'border-box'
                    }}
                />
            </div>

            <div className="input-container" style={{ position: 'absolute', top: '740px', left: '0', width: '100%', height: '520px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    onClick={() => {
                        // handleSubmit('我約了公司的人見面');
                        const response = '好的,請問您要找誰呢?';
                        setResponseText(response);
                        const utterance = new SpeechSynthesisUtterance(response);
                        utterance.lang = 'zh-TW';
                        window.speechSynthesis.speak(utterance);
                    }}
                    style={{ fontSize: '40px', marginBottom: '20px', width: '90%', height: '100px' }}
                >
                    我約了公司的人見面
                </Button>

                <Button
                    onClick={() => handleSubmit('我來面試')}
                    style={{ fontSize: '40px', marginBottom: '20px', width: '90%', height: '100px' }}
                >
                    我來面試
                </Button>

                <Button
                    onClick={() => handleSubmit('我要取貨或卸貨')}
                    style={{ fontSize: '40px', width: '90%', height: '100px' }}
                >
                    我要取貨或卸貨
                </Button>
            </div>



            <div className="mic-container" style={{ position: 'absolute', top: '1280px', left: '0', width: '1080px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {thinking ? (
                    <Spinner animation="grow" variant="success" style={{ width: '8rem', height: '8rem' }} />
                ) : (
                    <>
                        <Button
                            onClick={startListening}
                            disabled={isButtonDisabled}
                            style={{ width: '230px', height: '230px', borderRadius: '50%', backgroundColor: 'rgba(96, 138, 52, 1)', marginBottom: '10px' }}
                        >
                            {isListening ? (
                                <FontAwesomeIcon icon={faMicrophoneSlash} className="text-9xl text-gray-500" />
                            ) : (
                                <FontAwesomeIcon icon={faMicrophone} className="text-9xl" />
                            )}
                        </Button>
                        {countdown > 0 && (
                            <div style={{ color: 'grey' }}>{countdown}</div>
                        )}
                    </>
                )}
            </div>


            <div className="input-container" style={{ position: 'absolute', top: '1540px', left: '0', width: '100%', height: '320px', justifyContent: 'center', alignItems: 'center' }}>
                <form onSubmit={handleSubmit} style={{ height: '100%' }}>
                    <textarea
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        style={{ width: '100%', fontSize: '60px', height: '90%', boxSizing: 'border-box', border: '0.5px solid #908890', padding: '10px' }}
                    />
                </form>
            </div>
        </div>);
}

export default App;