import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import moment from 'moment-timezone';
import { FaRedo, FaUpload } from 'react-icons/fa';
import config from './config';

function Search() {
    const [userInput, setUserInput] = useState('');
    const [results, setResults] = useState(null);
    const [noClothingDetected, setNoClothingDetected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cameraError, setCameraError] = useState(false);
    const [videoConstraints, setVideoConstraints] = useState({ facingMode: 'user' });
    const [uploadedImage, setUploadedImage] = useState(null);
    const [pastedImage, setPastedImage] = useState(null);
    const [modalImage, setModalImage] = useState(null);
    const [showCamera, setShowCamera] = useState(true); // State to control camera visibility
    const webcamRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setVideoConstraints({ facingMode: { exact: 'environment' } });
        } else {
            setVideoConstraints({ facingMode: 'user' });
        }
    }, []);

    const clearAllDataExceptImage = () => {
        setResults(null);
        setNoClothingDetected(false);
        setCameraError(false);
        setModalImage(null);
        setShowCamera(false); // Hide the camera after an image is uploaded or pasted
    };

    const handleImageClick = async () => {
        clearAllDataExceptImage(); // Clear all data except the image

        setLoading(true);

        let searchQuery = userInput.trim() ? userInput : 'last 10 days, top 10';

        const image = pastedImage || webcamRef.current.getScreenshot(); // Use pasted image or snapshot from webcam

        // If there's an existing uploaded image, retain it and append the new image
        setUploadedImage(image); // Set the captured or pasted image as the uploaded image

        const imageUrl = await uploadFile(image);

        if (imageUrl) {
            const aiResponse = await postImageAI(imageUrl, searchQuery);
            setLoading(false);
            if (aiResponse) {
                setResults(aiResponse);
            }
        } else {
            setLoading(false);
        }
    };

    const handleFileChange = async (event) => {
        clearAllDataExceptImage(); // Clear all data except the image

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPastedImage(reader.result);
                setUploadedImage(reader.result); // Set the uploaded image when a file is selected
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePaste = async (event) => {
        clearAllDataExceptImage(); // Clear all data except the image

        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPastedImage(reader.result);
                    setUploadedImage(reader.result); // Set the uploaded image when an image is pasted
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const postImageAI = async (imageUrl, searchQuery) => {
        try {
            const response = await axios.post(config.imageAi, {
                command: "image_ai_only",
                image_url: imageUrl
            });
            console.log('AI Processing Success:', JSON.stringify(response.data, null, 2));

            if (response.data === null) {
                console.log('無衣物被偵測');
                setNoClothingDetected(true);
            } else {
                const detectResult = response.data;
                const searchResponse = await axios.post(config.imageAi, {
                    command: "search_img",
                    user_input: searchQuery,
                    image_url: imageUrl,
                    detect_result: detectResult
                });
                console.log('Search Processing Success:', JSON.stringify(searchResponse.data, null, 2));
                return searchResponse.data;
            }
        } catch (error) {
            console.error('AI Processing Failed:', error);
            return null;
        }
    };

    const uploadFile = async (image) => {
        const filename = new Date().toISOString() + '.jpg';
        const blob = await fetch(image).then(res => res.blob());

        let formData = new FormData();
        formData.append('file', blob, filename);

        try {
            const response = await fetch(`${config.youngerBase}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('檔案上傳失敗');
            }

            const data = await response.json();
            console.log('檔案上傳成功:', data);
            const savedFilename = extractFilename(data.info);
            const imageUrl = `${config.youngerBase}/static/${savedFilename}`;
            return imageUrl;
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
            return null;
        }
    };

    const extractFilename = (info) => {
        const match = info.match(/'(.*?)'/);
        if (match && match.length > 1) {
            const filepath = match[1];
            return filepath.split('/').pop();
        }
        return null;
    };

    const handleClear = () => {
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach(track => track.stop());
        }
        window.location.reload();
    };

    const handleImageClickInTable = (imageUrl) => {
        setModalImage(imageUrl);
    };

    const handleCloseModal = () => {
        setModalImage(null);
    };

    return (
        <div className="container-fluid p-3" style={{ maxWidth: '1200px', margin: '0 auto' }}>
            <h1 className="text-center position-relative">YOUNGER AI 圖片搜尋訂單
                <button
                    type="button"
                    className="btn btn-primary position-absolute"
                    style={{ right: '-60px', fontSize: '1.5rem', padding: '0.5rem' }}
                    onClick={handleClear}
                >
                    <FaRedo />
                </button>
            </h1>

            <div className="input-group mb-3">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => fileInputRef.current.click()}
                >
                    +
                </button>
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="請輸入時間範圍及匹配結果數量。例如：「過去 10 天，前 3 名」"
                    className="form-control"
                    onPaste={handlePaste}
                />
                <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <button
                    type="button"
                    className="btn btn-primary ms-2 d-flex justify-content-center align-items-center"
                    onClick={handleImageClick}
                    style={{ width: '100px' }}  // Set the width to 100px
                >
                    <FaUpload className="me-1" /> 上傳
                </button>
            </div>

            {uploadedImage && (
                <div className="d-flex justify-content-center">
                    <img src={uploadedImage} alt="已上傳的照片" className="img-thumbnail" style={{ maxWidth: '350px', marginTop: '10px' }} />
                </div>
            )}

            {!pastedImage && showCamera && (
                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '20px' }}>
                    {!uploadedImage && (
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            className="img-fluid"
                            videoConstraints={videoConstraints}
                            onUserMediaError={() => setCameraError(true)}
                            onClick={handleImageClick}
                            style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }}
                        />
                    )}
                    {cameraError && (
                        <div className="position-absolute top-50 start-50 translate-middle text-danger fw-bold">
                            相機預覽失敗
                        </div>
                    )}
                </div>
            )}

            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '20%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center the loader exactly in the middle
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: '10px',
                    width: '220px', // Match the width of the webcam
                    height: '160px' // Match the height of the webcam
                }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">載入中...</span>
                    </div>
                    <p className="mt-2">AI 分析中，請稍候...</p>
                </div>
            )}

            <div className="mt-4">
                {results ? (
                    results.query_results.length > 0 ? (
                        <>
                            <h3>查詢條件</h3>
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <strong>時間從 (台灣):</strong> {moment(results.query.time_from).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss')}
                                </li>
                                <li className="list-group-item">
                                    <strong>時間至 (台灣):</strong> {moment(results.query.time_to).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss')}
                                </li>
                                <li className="list-group-item">
                                    <strong>輸出數量:</strong> {results.query.output_qty}
                                </li>
                            </ul>

                            <h3 className="mt-4">搜尋條件</h3>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '120px' }}>圖片</th>
                                            <th>類型</th>
                                            <th>風格</th>
                                            <th>圖案</th>
                                            <th>顏色</th>
                                            <th>材質</th>
                                            <th>用途</th>
                                            <th style={{ width: '420px' }}>描述</th>
                                            <th>備註</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img
                                                    src={results.detect_result.image_url}
                                                    alt="Detected"
                                                    className="img-fluid"
                                                    style={{ maxWidth: '100px', cursor: 'pointer' }}
                                                    onClick={() => handleImageClickInTable(results.detect_result.image_url)}
                                                />
                                            </td>
                                            <td>{results.detect_result.translation.type}</td>
                                            <td>{results.detect_result.translation.style}</td>
                                            <td>{results.detect_result.translation.pattern}</td>
                                            <td>{results.detect_result.translation.colors.join(', ')}</td>
                                            <td>{results.detect_result.translation.material}</td>
                                            <td>{results.detect_result.translation.usage}</td>
                                            <td>{results.detect_result.translation.description}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h3 className="mt-4">查詢結果</h3>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '120px' }}>圖片</th>
                                            <th>類型</th>
                                            <th>風格</th>
                                            <th>圖案</th>
                                            <th>顏色</th>
                                            <th>材質</th>
                                            <th>用途</th>
                                            <th style={{ width: '420px' }}>描述</th>
                                            <th>分數 (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.query_results.map((result, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <img
                                                        src={result.image_url}
                                                        alt="Result"
                                                        className="img-fluid"
                                                        style={{ maxWidth: '100px', cursor: 'pointer' }}
                                                        onClick={() => handleImageClickInTable(result.image_url)}
                                                    />
                                                </td>
                                                <td>{result.translation.type}</td>
                                                <td>{result.translation.style}</td>
                                                <td>{result.translation.pattern}</td>
                                                <td>{result.translation.colors.join(', ')}</td>
                                                <td>{result.translation.material}</td>
                                                <td>{result.translation.usage}</td>
                                                <td>{result.translation.description}</td>
                                                <td>{result.scorePercentage}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>沒有相關訂單，請調整搜尋條件。</div>
                    )
                ) : (
                    !loading && noClothingDetected ? (
                        <div style={{ color: 'red', fontWeight: 'bold' }}>無衣物被偵測</div>
                    ) : (
                        loading && <p>載入中，請稍候...</p>
                    )
                )}
            </div>

            {/* Modal for Enlarged Image */}
            {modalImage && (
                <div
                    className="modal show d-block"
                    tabIndex="-1"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
                    onClick={handleCloseModal}
                >
                    <div className="modal-dialog modal-fullscreen-sm-down modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body text-center">
                                <img src={modalImage} alt="Enlarged" className="img-fluid" style={{ width: '100%', height: 'auto' }} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Search;
