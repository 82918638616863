import React, { useState } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        if (username === 'datavan' && password === '123456') {
            Cookies.set('auth', 'datavan/123456', { expires: 1 });
            onLogin();
            navigate('/delivery'); // Redirect to /delivery
        } else {
            alert('Invalid credentials');
        }
    };



    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center" style={{ paddingTop: '3rem', paddingBottom: '2rem' }}>
                    <h4 style={{ fontSize: '1.5rem', marginBottom: '1rem', color: 'lightgrey' }}>Room Service Delivery</h4>

                    <h2 style={{ fontSize: '2.5rem', marginBottom: '2rem' }}>LOGIN</h2>
                    <Form onSubmit={handleLogin}>
                        <Form.Group controlId="formBasicEmail" style={{ marginBottom: '1.5rem' }}>
                            <Form.Control
                                type="text"
                                placeholder="ID"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                style={{ fontSize: '1.2rem' }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" style={{ marginBottom: '1.5rem' }}>
                            <Form.Control
                                type="password"
                                placeholder="パスワード"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ fontSize: '1.2rem' }}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" style={{ fontSize: '1.2rem', padding: '10px 20px' }}>
                            ログイン
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
