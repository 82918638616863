import React, { useState, useEffect, forwardRef } from 'react';
import { Button, Form, Container, Row, Col, ListGroup, Card, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import moment from 'moment-timezone';
import config from './config'; // Make sure to import your config
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Login from './Login'; // Import the Login component

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <button
        type="button"
        className="form-control"
        style={{ fontSize: '1.2rem', textAlign: 'left' }}
        onClick={onClick}
        ref={ref}
    >
        {value}
    </button>
));

const ServiceReport = () => {
    const [rooms, setRooms] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]); // Change to array for multiple selections
    const [startDate, setStartDate] = useState(new Date()); // Default to today
    const [endDate, setEndDate] = useState(new Date()); // Default to today
    const [reportData, setReportData] = useState(null);
    const [showWarning, setShowWarning] = useState(false); // State to track whether to show the warning message
    const [showAll, setShowAll] = useState(true); // State to control the filter, default is true (show all)
    const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication

    const navigate = useNavigate();

    useEffect(() => {
        const auth = Cookies.get('auth');
        if (auth === 'datavan/123456') {
            setIsAuthenticated(true);
        } else {
            navigate('/login'); // Redirect to login page if not authenticated
        }
    }, [navigate]);

    useEffect(() => {
        if (isAuthenticated) {
            // Fetch room data from the settings API
            const fetchSettings = async () => {
                try {
                    const response = await axios.post(config.servicesUrl, {
                        command: "get_settings",
                        hotel_id: "2222"
                    });
                    const wifiData = response.data.wifi;
                    const roomList = [];
                    for (let key in wifiData) {
                        wifiData[key].forEach(item => {
                            roomList.push(item.rooms);
                        });
                    }
                    setRooms(roomList);
                } catch (error) {
                    console.error('Failed to fetch settings:', error);
                }
            };

            fetchSettings();
        }
    }, [isAuthenticated]);

    const handleGenerateReport = async () => {
        if (selectedRooms.length === 0) {
            setShowWarning(true);
            return;
        }
        setShowWarning(false);

        try {
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
            const response = await axios.post(config.servicesUrl, {
                command: "service_reports",
                room: selectedRooms.join(','), // Join the selected rooms into a comma-separated string
                start_date: formattedStartDate,
                end_date: formattedEndDate
            });
            const groupedData = groupByRoom(response.data);
            console.log('Grouped Data:', groupedData); // Debug: log grouped data
            setReportData(groupedData);
        } catch (error) {
            console.error('Failed to fetch report:', error);
        }
    };

    const handleRoomChange = (e) => {
        const options = e.target.options;
        const selected = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selected.push(options[i].value);
            }
        }
        setSelectedRooms(selected);
    };

    const groupByRoom = (data) => {
        return data.reduce((acc, curr) => {
            const room = curr.room;
            if (!acc[room]) {
                acc[room] = [];
            }
            acc[room].push(curr);
            return acc;
        }, {});
    };

    const formatDate = (dateString) => {
        return dateString ? moment(dateString).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss') : '';
    };

    const calculateServiceTime = (start, end) => {
        if (!start || !end) return '';
        const duration = moment.duration(moment(end).diff(moment(start)));
        const minutes = Math.floor(duration.asMinutes());
        return `${minutes} 分`;
    };

    const filteredReportData = reportData && showAll
        ? reportData
        : reportData && Object.keys(reportData).reduce((acc, room) => {
            const filteredTasks = reportData[room].filter(task => task.checked);
            if (filteredTasks.length) {
                acc[room] = filteredTasks;
            }
            return acc;
        }, {});

    const downloadCSV = () => {
        if (!filteredReportData) return;

        const csvRows = [];
        const headers = ['部屋', '商品名', '価格', '数量', '合計', 'リクエスト時間', '請求日', 'サービスが提供されました', 'チェック時間', 'サービス時間'];
        csvRows.push(headers.join(','));

        Object.keys(filteredReportData).forEach(room => {
            filteredReportData[room].forEach(service => {
                const row = [
                    room,
                    service.name,
                    service.price,
                    service.qty,
                    service.total,
                    formatDate(service.request_timestamp),
                    service.bill_date,
                    service.checked ? 'はい' : 'いいえ',
                    formatDate(service.checked_timestamp),
                    calculateServiceTime(service.request_timestamp, service.checked_timestamp)
                ];
                csvRows.push(row.join(','));
            });
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob(["\uFEFF" + csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "service_report.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    if (!isAuthenticated) {
        return <Login onLogin={() => setIsAuthenticated(true)} />;
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col md={8}>
                    <h2 style={{ fontSize: '2rem', marginBottom: '2rem', textAlign: 'center' }}>Service Report</h2>
                    <Form>
                        <Form.Group controlId="formRoom" style={{ marginBottom: '1.5rem' }}>
                            <Form.Label>部屋 - 複数選択可能</Form.Label>
                            <Form.Control
                                as="select"
                                multiple // Enable multiple selection
                                value={selectedRooms}
                                onChange={handleRoomChange}
                                style={{ fontSize: '1.2rem' }}
                            >
                                {rooms.map((room, index) => (
                                    <option key={index} value={room}>
                                        {room}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {showWarning && (
                            <Alert variant="warning" style={{ marginBottom: '1.5rem', color: 'red' }}>
                                部屋を選択してください
                            </Alert>
                        )}

                        <Form.Group controlId="formStartDate" style={{ marginBottom: '1.5rem' }}>
                            <Form.Label>開始日</Form.Label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="yyyy-MM-dd"
                                customInput={<CustomDateInput />}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEndDate" style={{ marginBottom: '1.5rem' }}>
                            <Form.Label>終了日</Form.Label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                dateFormat="yyyy-MM-dd"
                                customInput={<CustomDateInput />}
                            />
                        </Form.Group>
                        <p style={{ fontSize: '0.8rem', color: 'blue' }}>
                            日付は請求日を意味し、締め時間は正午12:00です。
                        </p>

                        <Button variant="primary" onClick={handleGenerateReport} style={{ fontSize: '1.2rem', padding: '10px 20px' }}>
                            レポートを生成
                        </Button>
                    </Form>

                    {reportData && (
                        <>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <span style={{ fontSize: '0.8rem', marginTop: '5px' }}>{showAll ? "未完成のタスクに切り替え" : "全てのタスクに切り替え"}</span>
                                    </div>
                                }
                                checked={showAll}
                                onChange={() => setShowAll(!showAll)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center'
                                }}
                            />
                            <div style={{ marginTop: '2rem', textAlign: 'right' }}>
                                <Button variant="secondary" onClick={downloadCSV} style={{ fontSize: '1rem', padding: '5px 10px', marginBottom: '1rem' }}>
                                    ダウンロード CSV
                                </Button>
                            </div>
                            <div style={{ marginTop: '2rem' }}>
                                {Object.keys(filteredReportData).length === 0 ? (
                                    <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>データがありません</p> // "No data available" in Japanese
                                ) : (
                                    Object.keys(filteredReportData).map((room) => (
                                        <Card key={room} style={{ marginBottom: '1rem', border: '3px solid orange' }}>
                                            <Card.Header style={{ fontSize: '2rem', color: 'blue' }}>{room} 号室</Card.Header>

                                            <ListGroup variant="flush">
                                                {Array.isArray(filteredReportData[room]) ? filteredReportData[room].map((service, index) => (
                                                    <ListGroup.Item key={index}>
                                                        <div><span style={{ color: 'lightgrey' }}>商品名:</span> <span>{service.name}</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>価格:</span> <span>{service.price} 円</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>数量:</span> <span>{service.qty}</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>合計:</span> <span>{service.total} 円</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>リクエスト時間:</span> <span>{formatDate(service.request_timestamp)}</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>請求日:</span> <span>{service.bill_date}</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>サービスが提供されました:</span>
                                                            <Form.Check
                                                                type="checkbox"
                                                                checked={service.checked}
                                                                readOnly
                                                                style={{ color: 'blue', display: 'inline-block', marginLeft: '10px' }}
                                                            />
                                                        </div>
                                                        <div><span style={{ color: 'lightgrey' }}>チェック時間:</span> <span>{formatDate(service.checked_timestamp)}</span></div>
                                                        <div><span style={{ color: 'lightgrey' }}>サービス時間:</span> <span>{calculateServiceTime(service.request_timestamp, service.checked_timestamp)}</span></div>
                                                    </ListGroup.Item>
                                                )) : (
                                                    <p>データが正しくありません</p>
                                                )}
                                            </ListGroup>
                                        </Card>



                                    ))
                                )}
                            </div>
                        </>
                    )}

                </Col>
            </Row>
        </Container>
    );
};

export default ServiceReport;
