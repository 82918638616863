import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Form, Container, Row, Col, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment-timezone';
import config from './config'; // Make sure to import your config
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Login from './Login'; // Import the Login component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

const Delivery = () => {
  const [rooms, setRooms] = useState([]);
  const [showAll, setShowAll] = useState(false); // State to control the filter
  const [countdown, setCountdown] = useState(30); // State for countdown timer
  const [showModal, setShowModal] = useState(false); // State for controlling the modal visibility
  const [currentServiceId, setCurrentServiceId] = useState(null); // State for storing the current service_id
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State for authentication

  const navigate = useNavigate();

  useEffect(() => {
    const auth = Cookies.get('auth');
    if (auth === 'datavan/123456') {
      setIsAuthenticated(true);
    } else {
      navigate('/login'); // Redirect to login page if not authenticated
    }
  }, [navigate]);

  const fetchServices = async () => {
    try {
      const response = await axios.post(config.servicesUrl, {
        command: "get_services",
        room: "" // or inputRoomNumber if needed
      });

      const noonToday = moment.tz("Asia/Tokyo").set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
      const noonYesterday = noonToday.clone().subtract(1, 'day');

      const filteredServices = response.data.filter(service => {
        const requestTime = moment(service.request_timestamp).tz('Asia/Tokyo');
        return requestTime.isAfter(noonYesterday); // Keep services from noon yesterday
      });

      const sortedServices = filteredServices.sort((a, b) => {
        if (a.cancelled && !b.cancelled) return 1;
        if (!a.cancelled && b.cancelled) return -1;
        return new Date(b.request_timestamp) - new Date(a.request_timestamp);
      });

      const cleanedServices = sortedServices.map(service => {
        return {
          ...service,
          name: service.name_ja.replace(/\(.*?\)/g, '').trim(),
          checked: service.checked || false, // Add checked property
          service_id: service.service_id // Ensure service_id is included
        };
      });

      const groupedServices = cleanedServices.reduce((acc, service) => {
        const roomNumber = service.room;
        const requestTime = moment(service.request_timestamp).tz('Asia/Tokyo');
        const now = moment().tz('Asia/Tokyo');
        const durationMinutes = now.diff(requestTime, 'minutes');
        const durationHours = Math.floor(durationMinutes / 60);
        const durationDisplay = durationHours > 0
          ? `${durationHours} 時間 ${durationMinutes % 60} 分`
          : `${durationMinutes} 分`;

        if (!acc[roomNumber]) {
          acc[roomNumber] = {
            roomNumber,
            tasks: []
          };
        }
        acc[roomNumber].tasks.push({
          id: service._id, // Use the correct identifier format
          name: service.name,
          quantity: service.qty,
          duration: durationDisplay,
          time: requestTime.format('YYYY/MM/DD-HH:mm'),
          checked: service.checked || false, // Add checked property
          service_id: service.service_id // Ensure service_id is included
        });
        return acc;
      }, {});

      setRooms(Object.values(groupedServices));
      console.log('Services fetched:', JSON.stringify(cleanedServices));
    } catch (error) {
      console.error('Failed to fetch services:', error);
      setTimeout(function () {
        window.location.reload();
      }, 5000);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchServices(); // Initial fetch
      const intervalId = setInterval(fetchServices, 30000); // Fetch every 30 seconds

      return () => clearInterval(intervalId); // Clear interval on component unmount
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => (prevCountdown > 0 ? prevCountdown - 1 : 30));
    }, 1000);

    return () => clearInterval(countdownInterval); // Clear interval on component unmount
  }, []);

  const completeService = async () => {
    const newRooms = [...rooms];
    let updated = false;
    for (let room of newRooms) {
      for (let task of room.tasks) {
        if (task.service_id === currentServiceId) {
          task.checked = !task.checked;
          updated = true;
          break;
        }
      }
      if (updated) break;
    }
    setRooms(newRooms);

    console.log('Completing service:', currentServiceId); // Log the service_id for debugging

    // Call the API to mark the task as done
    try {
      const response = await axios.post(config.servicesUrl, {
        command: "done_service",
        service_id: currentServiceId, // Use service_id
        checked: true
      });
      console.log('Service update response:', response.data);

      if (response.status === 200) {
        setTimeout(() => {
          fetchServices(); // Fetch services again after a delay if the response is successful
        }, 500); // 1-second delay
      }
    } catch (error) {
      console.error('Failed to update service:', error);
    }

    setShowModal(false); // Hide the modal after completing the service
  };

  const handleRowClick = (service_id) => {
    setCurrentServiceId(service_id);
    setShowModal(true);
  };

  const filteredRooms = rooms.map(room => ({
    ...room,
    tasks: showAll ? room.tasks : room.tasks.filter(task => !task.checked)
  }));

  const allTasksCompleted = filteredRooms.every(room => room.tasks.length === 0);

  if (!isAuthenticated) {
    return <Login onLogin={() => setIsAuthenticated(true)} />;
  }

  return (
    <Container fluid className="container-fullscreen">
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <Button
          variant="secondary"
          style={{ fontSize: '1rem', padding: '5px 10px' }}
          onClick={() => window.open('/servicereport', '_blank')}
        >
          <FontAwesomeIcon icon={faHistory} />
        </Button>
      </div>
      <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, paddingBottom: '10px' }}>

        <h2 style={{ textAlign: 'center', margin: '5px 0' }}>今日の任務</h2>

        <p style={{ textAlign: 'center', margin: '5px 0', fontSize: '0.5rem', color: 'blue' }}>
          サービスは、正午の12:00以降の過去24時間のみ表示されます
        </p>

        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '10px', marginBottom: '10px', position: 'relative' }}>
          <div style={{ fontSize: '0.8rem', color: 'red', marginRight: '10px' }}>
            更新:{countdown} 秒
          </div>
          <Form.Check
            type="switch"
            id="custom-switch"
            label={
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ fontSize: '0.8rem', marginTop: '5px' }}>{showAll ? "未完成のタスクに切り替え" : "全てのタスクに切り替え"}</span>
              </div>
            }
            checked={showAll}
            onChange={() => setShowAll(!showAll)}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          />

        </div>



      </div>
      {allTasksCompleted ? (
        <div style={{ textAlign: 'center', marginTop: '20px', fontSize: '1.6rem', color: 'black' }}>
          全てのサービス項目が完了しました、お疲れ様です
        </div>
      ) : (
        <Row className="row-fullscreen">
          {filteredRooms.map((room, roomIndex) => (
            room.tasks.length > 0 && (
              <Col key={roomIndex} className="card-custom">
                <Card style={{ border: '3px solid orange' }}>
                  <Card.Body>
                    <Card.Title style={{ color: '#0d6efd', fontWeight: 'bold' }}>{room.roomNumber} 号室</Card.Title>
                    <ListGroup variant="flush">
                      {room.tasks.map((task, taskIndex) => {
                        const isLastItem = taskIndex === room.tasks.length - 1;
                        return (
                          <ListGroup.Item
                            key={taskIndex}
                            style={{
                              borderBottom: isLastItem ? 'none' : '1px solid #dee2e6',
                              backgroundColor: taskIndex % 2 === 0 ? '#d3d3d3' : '#ffffff', // Higher contrast background colors for even and odd rows
                              cursor: 'pointer' // Indicate that the row is clickable
                            }}
                            onClick={() => handleRowClick(task.service_id)} // Handle row click
                          >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <span style={{ maxWidth: '290px', display: 'block', wordWrap: 'break-word' }}>
                                <span style={{ color: 'purple', fontWeight: 'bold' }}>{task.name}</span> &nbsp;&nbsp;x&nbsp;&nbsp;
                                <span style={{ color: 'red', fontWeight: 'bold', fontSize: '1.4em' }}>{task.quantity}</span>
                              </span>
                              <Form.Check
                                type="checkbox"
                                label={task.checked ? "完成" : "未完成"}
                                checked={task.checked}
                                readOnly // Make it readOnly since we handle the click event on the row
                              />
                            </div>
                            <div style={{ fontSize: '12px', color: '#6c757d' }}>
                              <div>{task.duration}</div>
                              <div>{task.time}</div>
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Card.Body>
                </Card>
              </Col>
            )
          ))}
        </Row>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>サービスを完了しますか？</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentServiceId !== null && (
            <>
              <p>サービス: {rooms.find(room => room.tasks.find(task => task.service_id === currentServiceId)).tasks.find(task => task.service_id === currentServiceId).name}</p>
              <p>数量: {rooms.find(room => room.tasks.find(task => task.service_id === currentServiceId)).tasks.find(task => task.service_id === currentServiceId).quantity}</p>
              <p>この操作は取り消せません。</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            キャンセル
          </Button>
          <Button variant="primary" onClick={completeService}>
            確認
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Delivery;
