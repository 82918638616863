import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Weather = ({ city }) => {
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        const fetchWeather = async () => {
            const apiKey = 'd2e0a8a66de3392f2402c0b936f552ca';
            const url = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`;

            try {
                const response = await axios.get(url);
                setWeatherData(response.data);
            } catch (error) {
                console.error('Error fetching weather data:', error);
            }
        };

        fetchWeather();
    }, [city]);

    if (!weatherData) {
        return <div>Loading...</div>;
    }

    const temperature = weatherData.main.temp;
    const iconURL = `http://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`;

    return (
        <div style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: 'lightgrey',  padding: '7px', borderRadius: '8px', display: 'flex', alignItems: 'center', gap: '10px', border: '1px solid lightgrey' }}>
            <div>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{city}</div>
                <div style={{ fontSize: '16px' }}>{temperature}°C</div>
            </div>
            <div style={{ backgroundColor: 'lightgrey', padding: '5px', borderRadius: '8px' }}>
                <img src={iconURL} alt="Weather icon" style={{ width: '50px', height: '50px' }} />
            </div>
        </div>
    );
};

export default Weather;
