import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';

const QueryDataPage = () => {
  const [queryData, setQueryData] = useState(null);

  useEffect(() => {
    const parseQueryString = (queryString) => {
      if (queryString.startsWith('?')) {
        queryString = queryString.substring(1);
      }

      if (!queryString) {
        return null; // No query string, return null
      }

      const params = queryString.split('&');
      const result = {};

      params.forEach(param => {
        const [key, value] = param.split('=');
        if (key && value !== undefined) {
          const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
          const keys = key.split('.');

          keys.reduce((acc, currentKey, index) => {
            if (index === keys.length - 1) {
              acc[currentKey] = decodedValue;
            } else {
              if (!acc[currentKey]) {
                if (!isNaN(keys[index + 1])) {
                  acc[currentKey] = [];
                } else {
                  acc[currentKey] = {};
                }
              }
            }
            return acc[currentKey];
          }, result);
        }
      });

      // Handle rooms array
      const rooms = result.order_detial?.rooms;
      if (rooms) {
        result.order_detial.rooms = Object.keys(rooms).map(key => rooms[key]);
      }

      // Calculate the number of nights between check-in and check-out
      const checkinDate = result.order_detial?.date?.checkin_date;
      const checkoutDate = result.order_detial?.date?.checkout_date;
      if (checkinDate && checkoutDate) {
        const checkin = new Date(checkinDate);
        const checkout = new Date(checkoutDate);
        const diffTime = Math.abs(checkout - checkin);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        result.order_detial.booking_info.nights = diffDays.toString();
      }

      return result;
    };

    const queryString = window.location.search;
    const data = parseQueryString(queryString);
    setQueryData(data);
  }, []);

  return (
    <Container fluid className="bg-white text-dark p-3">
      {queryData ? (
        <>
          <Row className="mb-3 justify-content-center">
            <Button
              onClick={() => window.print()}
              variant="primary"
              style={{ marginBottom: '20px', width: '300px' }}
              id="printButton"
            >
              Print
            </Button>

            <style>
              {`
    @media print {
      #printButton {
        display: none;
      }
    }
  `}
            </style>


          </Row>
          <Row className="mb-3">
            <Col>
              <img
                src="https://www.yukinotsuki-hotel.jp/wp-content/plugins/vikbooking/admin/resources/favicon.png"
                alt="Logo"
                style={{ width: '80px', height: '80px' }}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark p-3">
                <Card.Body>
                  <h4>Booking Details</h4>
                  <Row>
                    <Col md={4}>
                      <p><span style={{ color: 'lightgrey' }}>Booking ID:</span> <strong>{queryData.order_detial.booking_info.id}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Name:</span> <strong>{queryData.order_detial.customer_details.name}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Email:</span> <strong>{queryData.order_detial.customer_details.email}</strong></p>
                    </Col>
                    <Col md={4}>
                      <p><span style={{ color: 'lightgrey' }}>City:</span> <strong>{queryData.order_detial.customer_details.city}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Surname:</span> <strong>{queryData.order_detial.customer_details.surname}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Phone:</span> <strong>{queryData.order_detial.customer_details.telephone}</strong></p>
                    </Col>
                    <Col md={4}>
                      <p><span style={{ color: 'lightgrey' }}>Check-in:</span> <strong>{queryData.order_detial.date.checkin_date}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Check-out:</span> <strong>{queryData.order_detial.date.checkout_date}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Nights:</span> <strong>{queryData.order_detial.booking_info.nights}</strong></p>
                      <p><span style={{ color: 'lightgrey' }}>Special Requests:</span> <strong>{queryData.order_detial.customer_details.special_requests || 'None'}</strong></p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>


          <Row className="mb-3">
            <Col>
              <Card className="bg-white text-dark mb-3">
                <Card.Body>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>#</th>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>Room Number</th>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>Room Type</th>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>Adults</th>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>Children</th>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>Name</th>
                        <th style={{ backgroundColor: 'lightgrey', color: 'black' }}>Cost (JPY)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {queryData.order_detial.rooms.map((room, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{room.room_num}</td>
                          <td>{room.room_type}</td>
                          <td>{room.adults}</td>
                          <td>{room.children}</td>
                          <td>{room.t_last_name}{room.t_first_name}</td>
                          <td>¥ {room.cust_cost}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="bg-white text-dark p-3">
                <Card.Body>
                  <Row>
                    <Col>
                      <p>Room Tax (VAT): ¥ {Math.floor(queryData.order_detial.row_info.tot_taxes)}</p>
                    </Col>
                    <Col>
                      <p>Room Total: ¥ {Math.floor(queryData.order_detial.row_info.total)}</p>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card.Body>
                <h5>Additional Notes</h5>
                <textarea
                  placeholder="Enter admin notes here..."
                  onChange={(e) => {
                    const updatedQueryData = { ...queryData };
                    updatedQueryData.order_detial.row_info.adminnotes = e.target.value;
                    setQueryData(updatedQueryData);
                  }}
                  rows={4} // Adjust the number of rows as needed
                  style={{
                    width: '100%',
                    fontWeight: 'bold', // Makes the text bold
                    padding: '10px', // Optional: Adds padding for better appearance
                    border: '4px dotted blue', // 2px dotted border in blue color
                  }}
                />
              </Card.Body>
            </Col>
          </Row>

        </>
      ) : (
        <p>No data</p>
      )}
    </Container>
  );
};

export default QueryDataPage;
