import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
  const [message, setMessage] = useState('');

  const handleTextToSpeech = async () => {
    try {
      // Initialize text to speech
      const initResponse = await axios.post('http://localhost:8080/v1/task/text_to_speech', {
        func: 'init',
        lang: 'zh',
        speech_rate: '1.2'
      }, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        }
      });

      if (initResponse.data.detail === 'success') {
        // Perform text to speech
        const speakResponse = await axios.post('http://localhost:8080/v1/task/text_to_speech', {
          func: 'speak',
          mode: 'flash',
          volume: '1.0',
          text: '你好 我是人工智慧小助理'
        }, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });

        setMessage(speakResponse.data.detail);
      }
    } catch (error) {
      console.error('Error performing text to speech:', error);
    }
  };

  return (
    <div>
      <h1>Text to Speech Demo</h1>
      <button onClick={handleTextToSpeech}>Initiate Text to Speech</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default App;
