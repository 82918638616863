import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const AdminControlPage = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the password cookie exists and matches the expected password
        const savedPassword = Cookies.get('adminPassword');
        if (savedPassword === 'datavan123') {
            setIsAuthenticated(true);
        }
    }, []);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === 'datavan123') {
            // Save the password to cookies
            Cookies.set('adminPassword', password, { expires: 7 }); // expires in 7 days
            setIsAuthenticated(true);
        } else {
            alert('Incorrect Password!');
        }
    };

    const handleLogout = () => {
        Cookies.remove('adminPassword');
        setIsAuthenticated(false);
        navigate('/admin'); // Redirect to the login page
    };

    return (
        <div className="admin-control-page">
            <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Room Services Admin Page</h1>
            {!isAuthenticated ? (
                <Form onSubmit={handlePasswordSubmit} style={{ maxWidth: '300px', margin: 'auto', marginTop: '50px' }}>
                    <InputGroup>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Enter password"
                            required
                        />
                        <Button type="submit">Submit</Button>
                    </InputGroup>
                </Form>
            ) : (
                <div className="links" style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h3>內容管理頁面</h3>
                    <Button onClick={() => navigate('/wifi')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>Wifi</Button>
                    <Button onClick={() => navigate('/services')} style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}>Services</Button>
                    <a
                        href="http://hotel.datavansolutions.com:9093/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                    >
                        <Button
                            style={{ margin: '10px', fontSize: '1.5rem', padding: '1rem 2rem' }}
                        >
                            Questions
                        </Button>
                    </a>

                    <br />
                    <p style={{ fontSize: '0.75rem', color: 'grey', marginTop: '10px' }}> Questions(問題按鈕管理) 將導到外部網頁, user id 請使用 default, 密碼與這管理頁相同</p>
                    <br />
                    <Button
                        onClick={handleLogout}
                        style={{ margin: '10px', backgroundColor: 'red', color: 'white', fontSize: '1.5rem', padding: '1rem 2rem' }}
                    >
                        Log Out
                    </Button>
                </div>



            )}
        </div>
    );
};

export default AdminControlPage;
